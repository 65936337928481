import React, { Component } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as LojaActions from "../store/actions/loja";
import { i18n } from '../_translate/i18n';

import HelmetFavicon from "../components/HelmetFavicon";
import Header from "../components/Header";
import BadgeCrm from "../components/BadgeCrm";
import Footer from "../components/Footer";

class Demografico extends Component {
  state = {
    idade: this.props.idade,
    genero: this.props.genero,
    ja_esteve: this.props.ja_esteve ? "Sim" : "Não",
  };

  setIdade = (idade) => () => {
    this.setState({
      idade: idade,
    });
  };

  setGenero = (genero) => () => {
    this.setState({
      genero: genero,
    });
  };

  setJaEsteve = (ja_esteve) => () => {
    this.setState({
      ja_esteve: ja_esteve,
    });
  };

  constructor(...args) {
    super(...args);

    //validação blacklist e pode gerar bonus
    const { user_black_list, user_can_bonus, campaign_id } = this.props;

    if (user_black_list) {
      this.props.history.push("/bonus-indisponivel-blacklist");
    }
    if (!user_can_bonus) {
      this.props.history.push("/bonus-indisponivel-limite");
    }

    if (campaign_id === 0) {
      this.props.history.push(`/?token=${localStorage.getItem("TOKEN")}`);
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    console.log("state demografico", this.state);
    await this.props.preencheDemografico(
      this.state.idade,
      this.state.genero,
      this.state.ja_esteve
    );

    if (this.props.show_pesquisa_satisfacao) {
      this.props.history.push("/pesquisa-satisfacao");
    } else {
      this.props.history.push("/bonus-gerado");
    }
  };

  render() {
    const {
      color_form_text,
      color,
      color_btn,
      favicon,
      color_background,
      color_text,
      color_btn_text_color,
    } = this.props;

    return (
      <div
        style={{ backgroundColor: color_background }}
        className="container_app"
      >
        <HelmetFavicon favicon={favicon} />
        <Header
          buttonColor={color_btn}
          showGoBack={true}
          goback={() => this.props.history.goBack()}
        />

        <Form
          method="POST"
          style={{ marginBottom: "120px" }}
          noValidate
          onSubmit={(e) => this.handleSubmit(e)}
        >
          <Row style={{ marginBottom: "10px" }}>
            <Col>
              <h1 style={{ color: color_text }} className="text-center">
                {i18n.t('pages.Demografico.sua_idade')}
              </h1>
            </Col>
          </Row>
          <Row>
            <Col lg="12" style={{ marginBottom: "20px" }}>
              <div className="text-center">
                <input
                  style={{ color: color_form_text }}
                  type="hidden"
                  value={this.state.user_id}
                />
                <div key={`custom-inline-radio1`} className="mb-3">
                  <Form.Check
                    onChange={this.setIdade("10 - 30 anos")}
                    checked={this.state.idade === "10 - 30 anos" ? true : false}
                    custom
                    style={{ color: color_form_text }}
                    inline
                    label={i18n.t('pages.Demografico.idade_10_30')}
                    type="radio"
                    id={`custom-inline-radio-1`}
                    className="boxOptionDemografico"
                  />
                  <Form.Check
                    onChange={this.setIdade("31 - 50 anos")}
                    checked={this.state.idade === "31 - 50 anos" ? true : false}
                    custom
                    style={{ color: color_form_text }}
                    inline
                    label={i18n.t('pages.Demografico.idade_31_50')}
                    type="radio"
                    id={`custom-inline-radio-2`}
                    className="boxOptionDemografico"
                  />
                  <Form.Check
                    onChange={this.setIdade("50+")}
                    checked={this.state.idade === "50+" ? true : false}
                    custom
                    style={{ color: color_form_text }}
                    inline
                    label={i18n.t('pages.Demografico.idade_50_ou_mais')}
                    type="radio"
                    id={`custom-inline-radio-3`}
                    className="boxOptionDemografico"
                  />
                </div>
              </div>
            </Col>
          </Row>

          <Row style={{ marginBottom: "10px" }}>
            <Col>
              <h1 style={{ color: color_text }} className="text-center">
              {i18n.t('pages.Demografico.genero')}
              </h1>
            </Col>
          </Row>
          <Row>
            <Col lg="12" style={{ marginBottom: "20px" }}>
              <div className="text-center">
                <div key={`custom-inline-radio2`} className="mb-3">
                  <Form.Check
                    onChange={this.setGenero("Masculino")}
                    checked={this.state.genero === "Masculino" ? true : false}
                    custom
                    inline
                    style={{ color: color_form_text }}
                    title="Teste"
                    label={i18n.t('pages.Demografico.masculino')}
                    type="radio"
                    id={`custom-inline-radio-4`}
                    className="boxOptionDemografico"
                  />
                  <Form.Check
                    onChange={this.setGenero("Feminino")}
                    checked={this.state.genero === "Feminino" ? true : false}
                    custom
                    inline
                    style={{ color: color_form_text }}
                    label={i18n.t('pages.Demografico.feminino')}
                    type="radio"
                    id={`custom-inline-radio-5`}
                    className="boxOptionDemografico"
                  />
                  <Form.Check
                    onChange={this.setGenero("Outro")}
                    checked={this.state.genero === "Outro" ? true : false}
                    custom
                    style={{ color: color_form_text }}
                    inline
                    label={i18n.t('pages.Demografico.outro')}
                    type="radio"
                    id={`custom-inline-radio-6`}
                    className="boxOptionDemografico"
                  />
                </div>
              </div>
            </Col>
          </Row>

          <Row style={{ marginBottom: "10px" }}>
            <Col>
              <h1 style={{ color: color_text }} className="text-center">
                {i18n.t('pages.Demografico.ja_esteve')}
              </h1>
            </Col>
          </Row>
          <Row>
            <Col lg="12" style={{ marginBottom: "20px" }}>
              <div className="text-center">
                <div key={`custom-inline-radio3`} className="mb-3">
                  <Form.Check
                    onChange={this.setJaEsteve("Sim")}
                    checked={this.state.ja_esteve === "Sim" ? true : false}
                    custom
                    style={{ color: color_form_text }}
                    inline
                    title={i18n.t('pages.Demografico.teste')}
                    label={i18n.t('pages.Demografico.sim')}
                    type="radio"
                    id={`custom-inline-radio-7`}
                    className="boxOptionDemografico"
                  />
                  <Form.Check
                    onChange={this.setJaEsteve("Não")}
                    checked={this.state.ja_esteve === "Não" ? true : false}
                    custom
                    style={{ color: color_form_text }}
                    inline
                    disabled={this.props.ja_esteve ? true : false}
                    label={i18n.t('pages.Demografico.nao')}
                    type="radio"
                    id={`custom-inline-radio-8`}
                    className="boxOptionDemografico"
                  />
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <div className="text-center">
                <Button
                  style={{
                    backgroundColor: color_btn,
                    borderColor: color_btn,
                    color: color_btn_text_color,
                  }}
                  className="cssButtonCelular"
                  type="submit"
                  disabled={
                    this.state.idade === "" ||
                    this.state.genero === "" ||
                    this.state.ja_esteve === ""
                  }
                >
                  {i18n.t('pages.Demografico.avancar')}
                </Button>
              </div>
            </Col>
          </Row>
        </Form>

        <BadgeCrm number="3" />

        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  favicon: state.customer.favicon,
  color: state.customer.cor_layout,
  show_data_nasc: state.customer.show_data_nasc,
  show_email: state.customer.show_email,
  show_pesquisa_satisfacao: state.customer.show_pesquisa_satisfacao,
  campaign_id: state.bonus.campaign_id,
  is_user: state.bonus.is_user,
  user_black_list: state.bonus.user_black_list,
  user_can_bonus: state.bonus.user_can_bonus,
  user_id: state.bonus.user_id,
  user_name: state.bonus.user_name,
  user_email: state.bonus.user_email,
  user_birth: state.bonus.user_birth,
  idade: state.bonus.idade,
  genero: state.bonus.genero,
  ja_esteve: state.bonus.ja_esteve,
  color_background: state.customer.color_background,
  color_text: state.customer.color_text,
  color_form_text: state.customer.color_form_text,
  color_btn: state.customer.color_btn,
  color_btn_text_color: state.customer.color_btn_text_color,
});

const mapDispatchToProps = (dispatch) => ({
  preencheDemografico: (idade, genero, ja_esteve) =>
    dispatch(LojaActions.preencheDemografico(idade, genero, ja_esteve)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Demografico)
);
