import i18n from 'i18next'
import { messages } from './languages';

i18n.init(
  {
    debug: false,
    defaultNS: 'translations',
    fallbackLng: 'pt',
    lng: 'pt',
    ns: ['translations'],
    react: {
      wait: true,
    },
    resources: messages,
  },
  (err, t) => {
    if (err) return console.log('something went wrong loading', err);
    t('key'); // -> same as i18next.t
  },
);

export { i18n };
