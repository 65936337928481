const INITIAL_STATE = {
  campaign_id: 0,
  image: "",
  garcom: "",
  celular: "",
  celularOnlyNumber: "",
  is_user: false,
  user_black_list: false,
  user_can_bonus: true,
  user_id: "",
  user_name: "",
  user_birth: "",
  user_email: "",
  idade: "",
  genero: "",
  ja_esteve: false,
  pesquisa_satisfacao: "",
  descricao: "",
  pesquisa_satisfacao2: "",
  descricao2: "",
  pesquisa_satisfacao3: "",
  descricao3: ""
};

export default function bonus(state = INITIAL_STATE, action) {
  if (action.type === "SELECTED_CAMPAIGN") {
    return {
      ...state,
      campaign_id: action.campaign_id,
      image: action.image
    };
  }
  if (action.type === "PREENCHE_USER") {
    return {
      ...state,
      celular: action.celular,
      celularOnlyNumber: action.celularOnlyNumber,
      is_user: action.is_user,
      user_black_list: action.user_black_list,
      user_can_bonus: action.user_can_bonus,
      user_id: action.user_id,
      user_name: action.user_name,
      user_birth: action.user_birth,
      user_email: action.user_email,
      idade: action.idade,
      genero: action.genero,
      ja_esteve: action.ja_esteve
    };
  }
  if (action.type === "PREENCHE_NOME_DATANIVER") {
    return {
      ...state,
      user_id: action.user_id,
      user_name: action.user_name,
      user_birth: action.user_birth,
      user_email: action.user_email
    };
  }

  if (action.type === "PREENCHE_DEMOGRAFICO") {
    return {
      ...state,
      idade: action.idade,
      genero: action.genero,
      ja_esteve: action.ja_esteve
    };
  }

  if (action.type === "PREENCHE_PESQUISA_SATISFACAO") {
    return {
      ...state,
      pesquisa_satisfacao: action.pesquisaSatisfacao,
      descricao: action.descricao
    };
  }
  if (action.type === "PREENCHE_PESQUISA_SATISFACAO2") {
    return {
      ...state,
      pesquisa_satisfacao2: action.pesquisaSatisfacao,
      descricao2: action.descricao
    };
  }
  if (action.type === "PREENCHE_PESQUISA_SATISFACAO3") {
    return {
      ...state,
      pesquisa_satisfacao3: action.pesquisaSatisfacao,
      descricao3: action.descricao
    };
  }
  if (action.type === "SELECTED_GARCOM") {
    return {
      ...state,
      garcom: action.garcomSelecionado
    };
  }
  if (action.type === "ZERAR_BONUS") {
    return {
      ...state,
      campaign_id: 0,
      image: "",
      celular: "",
      celularOnlyNumber: "",
      is_user: false,
      user_black_list: false,
      user_can_bonus: true,
      user_id: "",
      user_name: "",
      user_birth: "",
      idade: "",
      genero: "",
      ja_esteve: false,
      pesquisa_satisfacao: "",
      descricao: "",
      pesquisa_satisfacao2: "",
      descricao2: "",
      pesquisa_satisfacao3: "",
      descricao3: ""
    };
  }
  return state;
}
