import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import imgBonusIndisponivel from "../assets/images/icon_bonus_indisponivel.png";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { i18n } from "../_translate/i18n";

class LojaInadimplente extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <Container className="container_app">
        <Header showGoBack={false} goback={() => this.props.history.goBack()} />

        <Row style={{ marginBottom: "42px" }}>
          <Col className="text-center">
            <img src={imgBonusIndisponivel} alt="" style={{ width: "96px" }} />
            <p className="cssTextBonusIndisponivel">
              {i18n.t("pages.LojaInadimplente.clique_reconectar_internet")}
            </p>
            <p className="cssTextBonusIndisponivel">
              {i18n.t("pages.LojaInadimplente.loja_indisponivel")}
            </p>
          </Col>
        </Row>

        <Footer />
      </Container>
    );
  }
}

export default LojaInadimplente;
