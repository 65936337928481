import axios from "axios";
import { parse } from "query-string";
import { i18n } from '../../_translate/i18n';

//const API_URL = 'http://crmbonus.local/pages';
//const API_URL = 'https://homol.crmbonus.com/pages';
const API_URL = "https://www.crmbonus.com/pages";
const AUTHORIZATION = "Q1JNJkJvbnVzQVBJIzIwMTg=";

export async function serviceGetInfoLoja(token) {
  let retorno;
  await axios
    .get(`${API_URL}/api_get_info_loja`, {
      headers: {
        authorization: AUTHORIZATION,
        token: token,
      },
    })
    .then((response) => {
      //alert(JSON.stringify(response));
      if (response.data.status) {
        retorno = response.data.data;
        //jogando para o localStorage os dados da loja
        localStorage.setItem(
          "THEME",
          JSON.stringify({
            color_background: retorno.Customer.color_background,
            color_text: retorno.Customer.color_text,
            color_form_text: retorno.Customer.color_form_text,
            color_btn: retorno.Customer.color_btn,
            color_btn_text_color: retorno.Customer.color_btn_text_color,
          })
        );
        localStorage.setItem("ID", retorno.Customer.id);
        localStorage.setItem("COMPANY", retorno.Customer.company);
        localStorage.setItem(
          "TEXT_RESTAURANTE",
          retorno.Customer.text_restaurante
        );
        localStorage.setItem("COR_LAYOUT", retorno.Customer.cor_layout);
        localStorage.setItem("LOGO", retorno.Customer.logo);
        localStorage.setItem("FAVICON", retorno.Customer.favicon);
        localStorage.setItem(
          "CUSTOMER_STATUS_ID",
          retorno.Customer.customer_status_id
        );
        localStorage.setItem(
          "ASSOCIAR_GARCOM",
          retorno.Customer.associar_garcom
        );
        localStorage.setItem("IDIOMA", retorno.Customer.idioma);
        localStorage.setItem("MASK_CELULAR", retorno.Customer.mask_celular);
        localStorage.setItem("PAIS_CELULAR", retorno.Customer.pais_celular);
        localStorage.setItem("SHOW_DATA_NASC", retorno.Customer.show_data_nasc);
        localStorage.setItem("SHOW_EMAIL", retorno.Customer.show_email);
        localStorage.setItem(
          "SHOW_PESQUISA_SATISFACAO",
          retorno.Customer.show_pesquisa_satisfacao
        );
        localStorage.setItem(
          "SHOW_PESQUISA_SATISFACAO2",
          retorno.Customer.show_pesquisa_satisfacao2
        );
        localStorage.setItem(
          "SHOW_PESQUISA_SATISFACAO3",
          retorno.Customer.show_pesquisa_satisfacao3
        );
        localStorage.setItem(
          "SHOW_DEMOGRAFICO",
          retorno.Customer.show_demografico
        );
        localStorage.setItem(
          "PERGUNTA_PESQUISA_SATISFACAO2",
          retorno.Customer.pergunta_pesquisa_satisfacao2
        );
        localStorage.setItem(
          "PERGUNTA_PESQUISA_SATISFACAO3",
          retorno.Customer.pergunta_pesquisa_satisfacao3
        );
        localStorage.setItem("TOKEN", btoa(retorno.Customer.token));
        localStorage.setItem("COD_LINX", btoa(retorno.Customer.cod_linx));
        localStorage.setItem("CAMPAIGNS", JSON.stringify(retorno.Campaign));
        localStorage.setItem("GARCONS", JSON.stringify(retorno.CustomerWaiter));
        console.log(retorno.Customer.idioma);

        let lang = 'pt'

        switch (retorno.Customer.idioma) {
          case 'spa':
            lang = 'es'
            break;
          case 'py':
            lang = 'es';
            break;
          default:
            lang = 'pt'
            break;
        }

        i18n.changeLanguage(lang)

      } else {
        retorno = false;
      }
    })
    .catch((error) => {
      //alert(JSON.stringify(error));
      retorno = false;
    });
  return retorno;
}

export function getInfoLoja(retorno) {
  return {
    type: "UPDATE_CUSTOMER",
    id: retorno.Customer.id && retorno.Customer.id,
    company: retorno.Customer.company,
    text_restaurante: retorno.Customer.text_restaurante,
    cor_layout: retorno.Customer.cor_layout,
    logo: retorno.Customer.logo,
    favicon: retorno.Customer.favicon,
    text_end_generation_influencers:
      retorno.Customer.text_end_generation_influencers,
    customer_status_id: retorno.Customer.customer_status_id,
    dia_max_x_gerar_bonus: retorno.Customer.dia_max_x_gerar_bonus,
    dissociar_garcom_dias: retorno.Customer.dissociar_garcom_dias,
    associar_garcom: retorno.Customer.associar_garcom,
    idioma: retorno.Customer.idioma,
    color_background: retorno.Customer.color_background || "#fff",
    color_text: retorno.Customer.color_text || "#303030",
    color_form_text: retorno.Customer.color_form_text || "#303030",
    color_btn: retorno.Customer.color_btn || "#303030",
    color_btn_text_color: retorno.Customer.color_btn_text_color || "#fff",
    mask_celular: retorno.Customer.mask_celular,
    moeda: retorno.Customer.moeda,
    pais_celular: retorno.Customer.pais_celular,
    show_data_nasc: retorno.Customer.show_data_nasc,
    show_email: retorno.Customer.show_email,
    show_pesquisa_satisfacao: retorno.Customer.show_pesquisa_satisfacao,
    show_pesquisa_satisfacao2: retorno.Customer.show_pesquisa_satisfacao2,
    show_pesquisa_satisfacao3: retorno.Customer.show_pesquisa_satisfacao3,
    show_demografico: retorno.Customer.show_demografico,
    pergunta_pesquisa_satisfacao2:
      retorno.Customer.pergunta_pesquisa_satisfacao2,
    pergunta_pesquisa_satisfacao3:
      retorno.Customer.pergunta_pesquisa_satisfacao3,
    token: btoa(retorno.Customer.token),
    cod_linx: btoa(retorno.Customer.cod_linx),
    campaigns: retorno.Campaign,
    garcons: retorno.CustomerWaiter,
  };
}

export async function serviceGetInfoUser(stringified) {
  let retorno;
  await axios
    .get(`${API_URL}/api_get_info_user/?${stringified}`, {
      headers: {
        authorization: AUTHORIZATION,
      },
    })
    .then((response) => {
      if (response.data.status) {
        retorno = response.data.data;
      } else {
        retorno = false;
      }
    })
    .catch((error) => {
      retorno = false;
    });
  return retorno;
}

export function preencheDadosUser(
  getInfoUser,
  celular,
  celularOnlyNumber,
  countryCode
) {
  return {
    type: "PREENCHE_USER",
    celular: celular,
    celularOnlyNumber: celularOnlyNumber,
    is_user: getInfoUser.User.name ? true : false,
    countryCode: countryCode,
    user_black_list: getInfoUser.User.is_blacklist,
    user_can_bonus: getInfoUser.User.user_can_bonus,
    user_id: getInfoUser.User.id || "",
    user_name: getInfoUser.User.name || "",
    user_birth: getInfoUser.User.birth || "",
    user_email: getInfoUser.User.email || "",
    idade: getInfoUser.User.idade || "",
    genero: getInfoUser.User.genero || "",
    ja_esteve: getInfoUser.User.ja_esteve || "",
  };
}

export function SelectedCampaign(id, image) {
  return {
    type: "SELECTED_CAMPAIGN",
    campaign_id: id,
    image: image,
  };
}

export function preencheNomeDataNiver(
  user_id,
  user_name,
  user_birth,
  user_email
) {
  return {
    type: "PREENCHE_NOME_DATANIVER",
    user_id: user_id,
    user_name: user_name,
    user_birth: user_birth,
    user_email: user_email,
  };
}

export function preencheDemografico(idade, genero, ja_esteve) {
  return {
    type: "PREENCHE_DEMOGRAFICO",
    idade: idade,
    genero: genero,
    ja_esteve: ja_esteve,
  };
}

export function preenchePesquisaSatisfacao(pesquisaSatisfacao, descricao) {
  return {
    type: "PREENCHE_PESQUISA_SATISFACAO",
    pesquisaSatisfacao: pesquisaSatisfacao,
    descricao: descricao,
  };
}

export function preenchePesquisaSatisfacao2(pesquisaSatisfacao, descricao) {
  return {
    type: "PREENCHE_PESQUISA_SATISFACAO2",
    pesquisaSatisfacao: pesquisaSatisfacao,
    descricao: descricao,
  };
}

export function preenchePesquisaSatisfacao3(pesquisaSatisfacao, descricao) {
  return {
    type: "PREENCHE_PESQUISA_SATISFACAO3",
    pesquisaSatisfacao: pesquisaSatisfacao,
    descricao: descricao,
  };
}

export function selectedGarcom(garcomSelecionado) {
  return {
    type: "SELECTED_GARCOM",
    garcomSelecionado: garcomSelecionado,
  };
}

export async function serviceCadastraBonus(bonusInfo, lojaInfo) {
  const postData = {
    loja_id: lojaInfo.id,
    campaign_id: bonusInfo.campaign_id,
    text_end_generation_influencers: lojaInfo.text_end_generation_influencers,
    garcom: bonusInfo.garcom.name || "",
    celular: `${bonusInfo.celular}`,
    celularOnlyNumber: bonusInfo.celularOnlyNumber,
    user_id: bonusInfo.user_id,
    user_name: bonusInfo.user_name,
    user_birth: bonusInfo.user_birth,
    user_email: bonusInfo.user_email,
    idade: bonusInfo.idade,
    genero: bonusInfo.genero,
    ja_esteve: bonusInfo.ja_esteve,
    pesquisa_satisfacao: bonusInfo.pesquisa_satisfacao,
    descricao: bonusInfo.descricao,
    pesquisa_satisfacao2: bonusInfo.pesquisa_satisfacao2,
    descricao2: bonusInfo.descricao2,
    pesquisa_satisfacao3: bonusInfo.pesquisa_satisfacao3,
    descricao3: bonusInfo.descricao3,
  };

  const axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      authorization: AUTHORIZATION,
    },
  };

  let retorno = false;

  await axios
    .post(`${API_URL}/api_finaliza_compra_restaurante`, postData, axiosConfig)
    .then((res) => {
      if (res.data.status) {
        retorno = res;
      } else {
        retorno = false;
      }
    })
    .catch((err) => {
      retorno = false;
    });
  return retorno;
}

export function zerarInfoBonus() {
  return {
    type: "ZERAR_BONUS",
  };
}
