const messages = {
  es: {
    translations: {
      components: {
        Garcom: {
          modal_title: 'SELECIONE O GARÇOM',
          modal_btn_confirm: 'CONFIRMAR'
        },
        Campanhas: {
          btn_gerar: 'GENERAR BONO'
        },
        ModalCarregando: {
          loading: 'Carregando...'
        }
      },
      pages: {
        AddUsuario: {
          title_form_name: 'Escribe tu nombre',
          title_form_dtnascimento: 'E SUA DATA DE NASCIMENTO',
          title_form_email: 'DIGITE SEU E-MAIL',
          btn_avancar: 'Avanzar',
        },
        BonusGerado: {
          state_title: '¡El bono fue generado</br>con éxito!',
          state_return_brinquedo: 'Para resgatar, é só comprar em nosso site <a target="_blank" rel="noopener noreferrer" href="https://www.estrela.com.br/">www.estrela.com.br</a> e inserir o código recebido no campo cupom de desconto na hora do pagamento.',
          state_return_beauty: 'Para resgatar, é só comprar em nosso site <a target="_blank" rel="noopener noreferrer" href="https://www.estrelabeauty.com.br">www.estrelabeauty.com.br</a> e inserir o código recebido no campo cupom de desconto na hora do pagamento, ou ir até uma de nossas lojas e apresentar o código para a vendedora',
          state_return_a: 'Bono válido del',
          state_return_b: 'Un SMS de confirmación será enviado al número </br><b>',
          state_return_b2: 'al',
          state_return_c: '</b> (en los próximos minutos)',
          state_return_d: ', muestra este mensaje al momento de redimir tu bono.',
          state_return_obrigado: 'Gracias',
          state2_title: "SEU BÔNUS NÃO FOI GERADO!",
          state2_message: "Tente novamente recomeçando o processo.",
          acesse_store: "Acesse a e-store"
        },
        BonusIndisponivelBlackList: {
          seu_telefone_esta_indisponivel: 'Seu telefone está indisponível <br /> para receber bonificações.',
          voltar: 'VOLTAR'
        },
        BonusIndisponivelLimite: {
          user_limit: 'Você tem bônus a vencer ou já gerou seu limite de bônus da semana.',
          consulta_saldo: 'Consultar saldo',
          voltar: 'VOLTAR'
        },
        Celular: {
          msg_erro_pegar_dados: 'erro ao pegar os dados.',
          inserir_cel_ddd: 'Escribe tu número de celular',
          rebera_sms_confirmacao: 'Recibirás un SMS con la confirmación',
          avancar: 'Avanzar'
        },
        ConfirmaInfo: {
          h1_pergunta_nome: 'SEU NOME É ALEXANDRE?',
          sim: 'SIM',
          nao: 'NÃo'
        },
        Demografico: {
          sua_idade: 'EDAD',
          genero: 'GÉNERO',
          ja_esteve: '¿HAS ESTADO AQUÍ ANTES?',
          idade_10_30: '10 - 30 Años',
          idade_31_50: '31 - 50 Años',
          idade_50_ou_mais: '50++',
          masculino: 'Hombre',
          feminino: 'Mujer',
          outro: 'Otro',
          teste: 'Teste',
          sim: 'SI',
          nao: 'NO',
          avancar: 'Avanzar'
        },
        LojaErroConexao: {
          erro_internet: 'Sem conexão com a internet.',
          clique_reconectar_internet: 'Clique aqui para reconectar'
        },
        LojaInadimplente: {
          loja_pendencias: 'Sua empresa esta com pendências com a plataforma CRM&BONUS!',
          loja_indisponivel: 'Entre em contato com seu representante para voltar a usar agora mesmo.'
        },
        PesquisaSatisfacao: {
          como_foi_seu_atendimento: '¿CÓMO FUE EL ATENDIMIENTO?',
          ruim: 'Malo',
          medio: 'Regular',
          bom: 'Bueno',
          otimo: 'Excelente',
          finalizar: 'FINALIZAR',
          sua_msg: 'Danos tu opinión (opcional)'
        },
        PesquisaSatisfacao2: {
          finalizar: 'PARA FINALIZAR...',
          ruim: 'Malo',
          medio: 'Regular',
          bom: 'Bueno',
          otimo: 'Excelente',
          finalizar2: 'FINALIZAR',
          sua_msg: 'Danos tu opinión (opcional)'
        },
        PesquisaSatisfacao3: {
          finalizar: 'PARA FINALIZAR...',
          ruim: 'Malo',
          medio: 'Regular',
          bom: 'Bueno',
          otimo: 'Excelente',
          finalizar2: 'FINALIZAR',
          sua_msg: 'Danos tu opinión (opcional)'
        },
      }
    },
  },
};

export { messages };
