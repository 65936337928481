import React, { Component } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import InputMask from "react-input-mask";
import * as LojaActions from "../store/actions/loja";
import { i18n } from '../_translate/i18n';

import HelmetFavicon from "../components/HelmetFavicon";
import Header from "../components/Header";
import BadgeCrm from "../components/BadgeCrm";
import Footer from "../components/Footer";

class AddUsuario extends Component {
  state = {
    user_id: this.props.user_id,
    name: this.props.user_name,
    email: this.props.user_email,
    focus: false,
    focusDataNasc: false,
    focusEmauk: false,
  };

  constructor(...args) {
    super(...args);
    let dataNasc = this.props.user_birth;
    if (dataNasc) {
      dataNasc = dataNasc.split("-");
      dataNasc = dataNasc[2] + "/" + dataNasc[1] + "/" + dataNasc[0];
    }
    const dataNascOnlyNumber = dataNasc.match(/\d/g) || [];
    const _dataNasc = dataNascOnlyNumber.join("");

    //validação blacklist e pode gerar bonus
    const { user_black_list, user_can_bonus, campaign_id } = this.props;
    if (user_black_list) {
      this.props.history.push("/bonus-indisponivel-blacklist");
    }
    if (!user_can_bonus) {
      this.props.history.push("/bonus-indisponivel-limite");
    }

    if (campaign_id === 0) {
      this.props.history.push(`/?token=${localStorage.getItem("TOKEN")}`);
    }

    this.state = {
      ...this.state,
      validated: false,
      dataNasc: dataNasc,
      dataNascOnlyNumber: _dataNasc,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.setState({ ...this.state, validated: true });

    await this.props.preencheNomeDataNiver(
      this.state.user_id,
      this.state.name,
      this.state.dataNasc,
      this.state.email
    );
    if (this.props.show_demografico) {
      this.props.history.push("/demografico");
    } else if (this.props.show_pesquisa_satisfacao) {
      this.props.history.push("/pesquisa-satisfacao");
    } else {
      this.props.history.push("/bonus-gerado");
    }
  };

  render() {
    const { validated } = this.state;
    const {
      color,
      color_text,
      color_btn,
      color_btn_text_color,
      show_data_nasc,
      show_email,
      color_form_text,
      favicon,
      color_background,
    } = this.props;
    return (
      <div
        style={{ backgroundColor: color_background }}
        className="container_app"
      >
        <HelmetFavicon favicon={favicon} />
        <Header
          buttonColor={color_btn}
          showGoBack={true}
          goback={() => this.props.history.goBack()}
        />

        <Form
          method="POST"
          style={{ marginBottom: "120px" }}
          noValidate
          validated={validated}
          onSubmit={(e) => this.handleSubmit(e)}
        >
          <Row style={{ marginBottom: "10px" }}>
            <Col>
              <h1 style={{ color: color_text }} className="text-center">
                {i18n.t("pages.AddUsuario.title_form_name")}
              </h1>
            </Col>
          </Row>
          <Row>
            <Col lg="12" style={{ marginBottom: "20px" }}>
              <div className="text-center">
                <input type="hidden" value={this.state.user_id} />
                <Form.Control
                  className="cssInputCelular"
                  style={{ color: color_form_text }}
                  required
                  type="text"
                  onChange={(event) => {
                    this.setState({
                      ...this.state,
                      name: event.target.value,
                      focus: true,
                    });
                  }}
                  value={this.state.name}
                  isValid={this.state.name.length >= 3}
                  isInvalid={this.state.name.length < 3}
                  autoFocus={true}
                />
              </div>
            </Col>
          </Row>

          {show_data_nasc && (
            <Row>
              <Col style={{ marginBottom: "10px" }}>
                <h1 style={{ color: color_text }} className="text-center">
                  {i18n.t("pages.AddUsuario.title_form_dtnascimento")}
                </h1>
              </Col>

              <Col lg="12" style={{ marginBottom: "20px" }}>
                <div className="text-center">
                  <InputMask
                    mask="99/99/9999"
                    style={{ color: color_form_text }}
                    onChange={(event) => {
                      const dataNasc = event.target.value;
                      const dataNascOnlyNumber = dataNasc.match(/\d/g) || [];
                      const _dataNasc = dataNascOnlyNumber.join("");
                      this.setState({
                        ...this.state,
                        dataNasc: dataNasc,
                        dataNascOnlyNumber: _dataNasc,
                        focusDataNasc: true,
                      });
                    }}
                    value={this.state.dataNasc}
                    isValid={this.state.dataNascOnlyNumber.length >= 8}
                    isInvalid={this.state.dataNascOnlyNumber.length < 8}
                  >
                    {(inputProps) => (
                      <Form.Control
                        className="cssInputCelular"
                        type="tel"
                        placeholder="__/__/____"
                        onChange={inputProps.onChange}
                        value={inputProps.value}
                        isValid={inputProps.isValid}
                        isInvalid={inputProps.isInvalid}
                      />
                    )}
                  </InputMask>
                </div>
              </Col>
            </Row>
          )}

          {show_email && (
            <Row>
              <Col>
                <h1 className="text-center">{i18n.t("pages.AddUsuario.title_form_email")}</h1>
              </Col>

              <Col lg="12" style={{ marginBottom: "39px" }}>
                <div className="text-center">
                  <Form.Control
                    style={{
                      color: color_form_text,
                    }}
                    className="cssInputCelular"
                    required
                    type="email"
                    onChange={(event) => {
                      this.setState({
                        ...this.state,
                        email: event.target.value,
                      });
                    }}
                    value={this.state.email}
                    isValid={this.state.email.length >= 3}
                    isInvalid={this.state.email.length < 3}
                  />
                </div>
              </Col>
            </Row>
          )}

          <Row>
            <Col lg="12">
              <div className="text-center">
                <Button
                  style={{
                    backgroundColor: color_btn,
                    borderColor: color_btn,
                    color: color_btn_text_color,
                  }}
                  className="cssButtonCelular"
                  type="submit"
                  disabled={
                    show_data_nasc
                      ? this.state.name.length < 3 ||
                        this.state.dataNascOnlyNumber.length < 8
                      : this.state.name.length < 3
                  }
                >
                  {i18n.t("pages.AddUsuario.btn_avancar")}
                </Button>
              </div>
            </Col>
          </Row>
        </Form>

        <BadgeCrm number="3" />

        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  favicon: state.customer.favicon,
  color: state.customer.cor_layout,
  show_data_nasc: state.customer.show_data_nasc,
  show_email: state.customer.show_email,
  show_pesquisa_satisfacao: state.customer.show_pesquisa_satisfacao,
  show_demografico: state.customer.show_demografico,
  campaign_id: state.bonus.campaign_id,
  is_user: state.bonus.is_user,
  user_black_list: state.bonus.user_black_list,
  user_can_bonus: state.bonus.user_can_bonus,
  user_id: state.bonus.user_id,
  user_name: state.bonus.user_name,
  user_email: state.bonus.user_email,
  user_birth: state.bonus.user_birth,
  color_background: state.customer.color_background,
  color_text: state.customer.color_text,
  color_form_text: state.customer.color_form_text,
  color_btn: state.customer.color_btn,
  color_btn_text_color: state.customer.color_btn_text_color,
});

const mapDispatchToProps = (dispatch) => ({
  preencheNomeDataNiver: (user_id, user_name, user_birth, user_email) =>
    dispatch(
      LojaActions.preencheNomeDataNiver(
        user_id,
        user_name,
        user_birth,
        user_email
      )
    ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddUsuario)
);
