import React, { Component } from 'react';
import Helmet from 'react-helmet';

export default class HelmetFavicon extends Component {
    render() {
        const { favicon } = this.props;
        return (
            <Helmet>
                <link rel="icon" type="image/png" sizes="16x16" href={favicon} />
                <link rel="icon" type="image/png" sizes="32x32" href={favicon} />
                <link rel="apple-touch-icon" sizes="180x180" href={favicon} />
            </Helmet>
        );
    }
}
