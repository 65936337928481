import React from "react";
import ReactDOM from "react-dom";
import Index from "./pages/Index";
import LojaInadimplente from "./pages/LojaInadimplente";
import LojaErroConexao from "./pages/LojaErroConexao";
import Celular from "./pages/Celular";
import ConfirmaInfo from "./pages/ConfirmaInfo";
import BonusIndisponivelBlackList from "./pages/BonusIndisponivelBlackList";
import BonusIndisponivelLimite from "./pages/BonusIndisponivelLimite";
import AddUsuario from "./pages/AddUsuario";
import Demografico from "./pages/Demografico";
import BonusGerado from "./pages/BonusGerado";
import PesquisaSatisfacao from "./pages/PesquisaSatisfacao";
import PesquisaSatisfacao2 from "./pages/PesquisaSatisfacao2";
import PesquisaSatisfacao3 from "./pages/PesquisaSatisfacao3";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Switch>
        <Route path="/" exact={true} component={Index} />
        <Route path="/inadimplente" exact={true} component={LojaInadimplente} />
        <Route path="/erro-conexao" exact={true} component={LojaErroConexao} />
        <Route path="/celular" component={Celular} />
        <Route
          path="/bonus-indisponivel-blacklist"
          component={BonusIndisponivelBlackList}
        />
        <Route
          path="/bonus-indisponivel-limite"
          component={BonusIndisponivelLimite}
        />
        <Route path="/confirmacao-info" component={ConfirmaInfo} />
        <Route path="/add-usuario" component={AddUsuario} />
        <Route path="/demografico" component={Demografico} />
        <Route path="/pesquisa-satisfacao" component={PesquisaSatisfacao} />
        <Route path="/pesquisa-satisfacao2" component={PesquisaSatisfacao2} />
        <Route path="/pesquisa-satisfacao3" component={PesquisaSatisfacao3} />
        <Route path="/bonus-gerado" component={BonusGerado} />
        {/* <Route path='*' component={ComponenteDePagina404} /> */}
      </Switch>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
