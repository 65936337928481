import React, { Component } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as LojaActions from "../store/actions/loja";
import Header from "../components/Header";
import Footer from "../components/Footer";
import ModalCarregando from "../components/ModalCarregando";
import HelmetFavicon from "../components/HelmetFavicon";
import { BEAUT_ID, BRINQUEDOS_ID, OSKLEN_ID } from "../constants/constants";
import oslenBanner from "../assets/images/banner_antecipe_compras.jpg";
import { transformText } from "../utils/utils";
import { parsePhoneNumber } from "awesome-phonenumber";
import { i18n } from "../_translate/i18n";

class BonusGerado extends Component {
  state = {
    showModalCarregando: true,
    title: "",
    subtitle: "",
    id: localStorage.getItem("ID"),
  };

  async componentWillMount() {
    const { bonusInfo, lojaInfo, color_background } = this.props;
    const cadastraBonus = await LojaActions.serviceCadastraBonus(
      bonusInfo,
      lojaInfo
    );

    const international = `+${String(cadastraBonus.data.data.celular).replace(
      /\D/g,
      ""
    )}`;

    const phone = parsePhoneNumber(international);
    const phoneNational = phone.getNumber("national");

    if (cadastraBonus && phoneNational) {
      this.setState({
        title: i18n.t("pages.BonusGerado.state_title"),
        image: bonusInfo.image,
        subtitle: (() => {
          if (this.state.id === BRINQUEDOS_ID) {
            return `${i18n.t("pages.BonusGerado.state_return_brinquedo")}`;
          } else if (this.state.id === BEAUT_ID) {
            return `${i18n.t("pages.BonusGerado.state_return_beauty")}`;
          } else {
            return `${i18n.t("pages.BonusGerado.state_return_a")} ${
              cadastraBonus.data.data.validade_inicio
            } ${i18n.t("pages.BonusGerado.state_return_b2")} ${
              cadastraBonus.data.data.validade_fim
            }. ${i18n.t("pages.BonusGerado.state_return_b")} ${phoneNational} ${i18n.t("pages.BonusGerado.state_return_c")} ${
              transformText(lojaInfo.text_end_generation_influencers) || `${i18n.t("pages.BonusGerado.state_return_d")}`
            }`;
          }
        })(),
      });
    } else {
      this.setState({
        title: `${i18n.t("pages.BonusGerado.state2_title")}`,
        image: "",
        subtitle: `${i18n.t("pages.BonusGerado.state2_message")}`,
      });
    }
    this.props.zerarInfoBonus();
    this.setState({
      showModalCarregando: false,
    });

    setTimeout(() => {
      this.props.history.push(`/?token=${localStorage.getItem("TOKEN")}`);
    }, 20000);
  }

  render() {
    const {
      color,
      favicon,
      color_btn,
      color_btn_text_color,
      color_text,
      color_background,
    } = this.props;
    const { showModalCarregando, title, image, subtitle } = this.state;
    return (
      <div
        style={{ backgroundColor: color_background }}
        className="container_app"
      >
        <HelmetFavicon favicon={favicon} />
        <ModalCarregando show={showModalCarregando} />
        <Header
          buttonColor={color_btn}
          showGoBack={false}
          goback={() => this.props.history.goBack()}
        />

        <Row style={{ marginBottom: "46px" }}>
          <Col>
            <h1
              style={{ color: color_text }}
              className="text-center"
              dangerouslySetInnerHTML={{ __html: title }}
            />
            {image && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 40,
                }}
              >
                <Image
                  src={
                    this.state.id === OSKLEN_ID
                      ? oslenBanner
                      : `https://www.crmbonus.com/files/campanhas/${image}`
                  }
                  rounded
                  className="text-center"
                />
              </div>
            )}

            <p
              style={{ color: color_text }}
              className="cssTextBonusGerado"
              dangerouslySetInnerHTML={{ __html: subtitle }}
            />
          </Col>
        </Row>

        <Row style={{ marginBottom: "42px" }}>
          <Col>
            <div
              className="text-center"
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {this.state.id === OSKLEN_ID && (
                <a
                  style={{
                    padding: "10px",
                  }}
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.osklen.com.br/"
                >
                  {i18n.t("pages.BonusGerado.acesse_store")}
                </a>
              )}
              <Link
                style={{
                  color: color_btn_text_color,
                  backgroundColor: color_btn,
                  borderColor: color_btn,
                  fontSize: 22,
                }}
                className="cssButton cssButtonObrigado btn btn-primary"
                to={`/?token=${localStorage.getItem("TOKEN")}`}
              >
                {i18n.t("pages.BonusGerado.state_return_obrigado")}
              </Link>
            </div>
          </Col>
        </Row>

        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  favicon: state.customer.favicon,
  color: state.customer.cor_layout,
  bonusInfo: state.bonus,
  lojaInfo: state.customer,
  color_background: state.customer.color_background,
  color_text: state.customer.color_text,
  color_form_text: state.customer.color_form_text,
  color_btn: state.customer.color_btn,
  color_btn_text_color: state.customer.color_btn_text_color,
});

const mapDispatchToProps = (dispatch) => ({
  zerarInfoBonus: () => dispatch(LojaActions.zerarInfoBonus()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BonusGerado)
);
