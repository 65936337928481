import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap';
import crm from '../assets/images/crm.jpg';
class Footer extends Component {
    render() {
        return (
            <Row>
                <Col>
                    <div className="text-center">
                        <img src={crm} alt="CRM&Bônus" />
                    </div>
                </Col>
            </Row>
        )
    }
}

export default Footer