import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { i18n } from '../_translate/i18n';

export default class ModalCarregando extends Component {
    static defaultProps = {
        show: false
    }
    render() {
        return (
            <Modal
                show={this.props.show}
                backdrop='static'
                aria-labelledby="contained-modal-title-vcenter"
                centered
                size="sm"
            >
                <Modal.Body style={{ textAlign: "center" }}>
                    <img src="https://www.crmbonus.com/adm/assets/img/loading-spinner-grey.gif" alt="Carregando" />
                    <span>
                        &nbsp;&nbsp;{i18n.t("components.ModalCarregando.loading")}
                </span>
                </Modal.Body>
            </Modal>
        );
    }
}
