import React, { Component } from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import * as LojaActions from "../store/actions/loja";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ModalCarregando from "../components/ModalCarregando";
import { i18n } from '../_translate/i18n';

class Campanhas extends Component {
  state = {
    showModalCarregando: false,
  };

  handleSelectedCampaign = (id, image) => {
    this.setState({
      showModalCarregando: true,
    });
    this.props.selectedCampaign(id, image);
    this.props.history.push("/celular");
  };

  render() {
    const { showModalCarregando } = this.state;
    const {
      campaigns,
      color_text,
      color_btn,
      color_background,
      color_btn_text_color,
    } = this.props;
    let tot_campaigns = 0;
    campaigns.map((campaign) => (campaign.status ? tot_campaigns++ : 0));

    let xs = 12;
    let sm = 4;
    let md = 4;
    let lg = 4;
    let xl = 4;
    if (tot_campaigns === 1) {
      sm = 6;
      md = 6;
      lg = 6;
      xl = 6;
    }
    //const BASE_URL = 'http://crmbonus.local';
    const BASE_URL = "https://www.crmbonus.com";
    return (
      <Row
        style={{
          marginBottom: "50px",
          backgroundColor: color_background,
        }}
      >
        <ModalCarregando show={showModalCarregando} />
        {Object.keys(campaigns).length > 0 &&
          campaigns.map(
            (campaign, idx) =>
              campaign.status && (
                <Col
                  key={campaign.id}
                  xs={xs}
                  sm={sm}
                  md={md}
                  lg={lg}
                  xl={xl}
                  className="cssColCenter"
                  onClick={() => {
                    this.handleSelectedCampaign(campaign.id, campaign.photo);
                  }}
                >
                  <Card className="cssCard">
                    <Card.Img
                      variant="top"
                      className="cssCardImage"
                      src={`${BASE_URL}/files/campanhas/${campaign.photo}`}
                    />
                    <Card.Body>
                      <Card.Title
                        style={{ color: color_text }}
                        className="cssCardTitle"
                      >
                        {campaign.name}
                      </Card.Title>
                      <Card.Text
                        style={{ color: color_text }}
                        className="cssCardText"
                      >
                        {campaign.subtitle}
                      </Card.Text>
                      <Button
                        style={{
                          backgroundColor: color_btn,
                          borderColor: color_btn,
                          color: color_btn_text_color,
                        }}
                        className="cssButton btn-block btn btn-primary"
                        onClick={() => {
                          this.handleSelectedCampaign(campaign.id);
                        }}
                      >
                        {i18n.t("components.Campanhas.btn_gerar")}
                      </Button>
                    </Card.Body>
                  </Card>
                </Col>
              )
          )}
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  campaigns: state.customer.campaigns,
  color: state.customer.cor_layout,
  color_background: state.customer.color_background,
  color_text: state.customer.color_text,
  color_form_text: state.customer.color_form_text,
  color_btn: state.customer.color_btn,
  color_btn_text_color: state.customer.color_btn_text_color,
});

const mapDispatchToProps = (dispatch) => ({
  selectedCampaign: (id, image) =>
    dispatch(LojaActions.SelectedCampaign(id, image)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Campanhas)
);
