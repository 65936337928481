import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import imgBonusIndisponivel from "../assets/images/icon_bonus_indisponivel.png";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { i18n } from "../_translate/i18n";

class LojaErroConexao extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const to = `/?token=${localStorage.getItem("TOKEN")}`;
    const {
      color,
      color_background,
      color_btn,
      color_btn_text_color,
      color_text,
    } = this.props;
    return (
      <div
        style={{ backgroundColor: color_background }}
        className="container_app"
      >
        <Header
          buttonColor={color_btn}
          showGoBack={false}
          goback={() => this.props.history.goBack()}
        />

        <Row style={{ marginBottom: "42px" }}>
          <Col className="text-center">
            <img src={imgBonusIndisponivel} alt="" style={{ width: "96px" }} />
            <p
              style={{ color: color_text }}
              className="cssTextBonusIndisponivel"
            >
              {i18n.t("pages.LojaErroConexao.erro_internet")}
            </p>
            <Link
              style={{
                color: color_btn_text_color,
                backgroundColor: color_btn,
                borderColor: color_btn,
                width: "232px",
              }}
              className="cssButton btn btn-primary"
              to={to}
            >
              {i18n.t("pages.LojaErroConexao.loja_pendencias")}
            </Link>
          </Col>
        </Row>

        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  color: state.customer.cor_layout,
  color_background: state.customer.color_background,
  color_text: state.customer.color_text,
  color_form_text: state.customer.color_form_text,
  color_btn: state.customer.color_btn,
  color_btn_text_color: state.customer.color_btn_text_color,
});

const mapDispatchToProps = (dispatch) => ({});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LojaErroConexao)
);
