import React, { Component } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { library } from "@fortawesome/fontawesome-svg-core";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { getUserLocale } from "get-user-locale";
import { i18n } from "../_translate/i18n";

import {
  faUserCircle,
  faChevronDown,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import * as LojaActions from "../store/actions/loja";

import HelmetFavicon from "../components/HelmetFavicon";
import ModalCarregando from "../components/ModalCarregando";
import Header from "../components/Header";
import BadgeCrm from "../components/BadgeCrm";
import Footer from "../components/Footer";

library.add(faUserCircle, faChevronDown, faArrowLeft);

class Celular extends Component {
  state = {
    celular: "",
    cod: "",
    locale: "pt-BR",
    celularOnlyNumber: "",
    phone: "",
    focus: false,
    showModalCarregando: false,
  };

  constructor(...args) {
    super(...args);
    this.state = { ...this.state, validated: false };
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    const { bonusInfo } = this.props;
    //RN - Se nesta tela, não tiver com o campo campaign_id preenchido, voltar
    if (bonusInfo.campaign_id === 0) {
      this.props.history.push(`/?token=${localStorage.getItem("TOKEN")}`);
    }
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.setState({
      ...this.state,
      validated: true,
      showModalCarregando: true,
    });
    //montando queryString

    const parsed = [];
    parsed.cod_pais = this.state.cod;
    parsed.celular = this.state.celularOnlyNumber;
    parsed.id_loja = this.props.id_loja;
    const stringified = queryString.stringify(parsed);
    //fazendo consulta para pegar dados do usuario
    const getInfoUser = await LojaActions.serviceGetInfoUser(stringified);
    if (getInfoUser) {
      await this.props.preencheDadosUser(
        getInfoUser,
        this.state.celular,
        this.state.celularOnlyNumber,
        this.state.cod
      );
      this.props.history.push("/add-usuario");
    } else {
      alert(`${i18n.t("pages.Celular.msg_erro_pegar_dados")}`);
    }
  };

  handleOnChange = (value, data, event, formattedValue) => {
    this.setState({
      ...this.state,
      celular: value,
      cod: data.dialCode,
      celularOnlyNumber: value.slice(data.dialCode.length),
      focus: true,
    });
  };

  currentLocale() {
    const browserLocale = getUserLocale();

    const locales = {
      "es-MX": "mx",
      "en-US": "us",
      "pt-BR": "br",
    };

    return locales[browserLocale] || locales["pt-BR"];
  }

  prefferList() {
    const browserLocale = getUserLocale();

    const locales = {
      "es-MX": ["mx", "us"],
      "en-US": ["us"],
      "pt-BR": ["br"],
    };

    return locales[browserLocale] || locales["pt-BR"];
  }

  render() {
    const { validated, showModalCarregando } = this.state;
    const {
      color,
      color_btn_text_color,
      favicon,
      color_form_text,
      color_btn,
      color_background,
      color_text,
    } = this.props;

    return (
      <div
        style={{ backgroundColor: color_background }}
        className="container_app"
      >
        <HelmetFavicon favicon={favicon} />
        <ModalCarregando show={showModalCarregando} />
        <Header
          buttonColor={color_btn}
          showGoBack={true}
          goback={() => this.props.history.goBack()}
        />

        <Row style={{ marginBottom: "42px" }}>
          <Col>
            <h1 style={{ color: color_text }} className="text-center">
              {i18n.t("pages.Celular.inserir_cel_ddd")}
            </h1>
            <p style={{ color: color_text }} className="cssTextCelular">
              {i18n.t("pages.Celular.rebera_sms_confirmacao")}
            </p>
          </Col>
        </Row>

        <Form
          method="POST"
          style={{ marginBottom: "58px" }}
          noValidate
          validated={validated}
          onSubmit={(e) => this.handleSubmit(e)}
        >
          <div className="input-cellphone-container">
            <PhoneInput
              style={{ width: "auto" }}
              country={this.currentLocale()}
              preferredCountries={this.prefferList()}
              masks={{ mx: "..-....-....", br: "(..) .....-...." }}
              countryCodeEditable={false}
              inputProps={{
                name: "phone",
                required: true,
                autoFocus: true,
              }}
              onlyCountries={["mx", "us", "br"]}
              value={this.state.celular}
              onChange={this.handleOnChange}
            />
            <Button
              style={{
                backgroundColor: color_btn,
                borderColor: color_btn,
                color: color_btn_text_color,
              }}
              className="cssButtonCelular"
              type="submit"
              disabled={this.state.celularOnlyNumber.length < 10}
            >
              {i18n.t("pages.Celular.avancar")}
            </Button>
          </div>
        </Form>

        <BadgeCrm number="2" />

        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  id_loja: state.customer.id,
  color: state.customer.cor_layout,
  favicon: state.customer.favicon,
  mask_celular: state.customer.mask_celular,
  color_background: state.customer.color_background,
  color_text: state.customer.color_text,
  color_form_text: state.customer.color_form_text,
  color_btn: state.customer.color_btn,
  color_btn_text_color: state.customer.color_btn_text_color,
  bonusInfo: state.bonus,
});

const mapDispatchToProps = (dispatch) => ({
  preencheDadosUser: (getInfoUser, celular, celularOnlyNumber, countryCode) =>
    dispatch(
      LojaActions.preencheDadosUser(
        getInfoUser,
        celular,
        celularOnlyNumber,
        countryCode
      )
    ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Celular)
);
