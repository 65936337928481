const messages = {
  pt: {
    translations: {
      components: {
        Garcom: {
          modal_title: 'SELECIONE O GARÇOM',
          modal_btn_confirm: 'CONFIRMAR'
        },
        Campanhas: {
          btn_gerar: 'GERAR BÔNUS'
        },
        ModalCarregando: {
          loading: 'Carregando...'
        }
      },
      pages: {
        AddUsuario: {
          title_form_name: 'DIGITE SEU PRIMEIRO NOME',
          title_form_dtnascimento: 'E SUA DATA DE NASCIMENTO',
          title_form_email: 'DIGITE SEU E-MAIL',
          btn_avancar: 'AVANÇAR',
        },
        BonusGerado: {
          state_title: 'SEU BÔNUS FOI <br /> GERADO COM SUCESSO!',
          state_return_brinquedo: 'Para resgatar, é só comprar em nosso site <a target="_blank" rel="noopener noreferrer" href="https://www.estrela.com.br/">www.estrela.com.br</a> e inserir o código recebido no campo cupom de desconto na hora do pagamento.',
          state_return_beauty: 'Para resgatar, é só comprar em nosso site <a target="_blank" rel="noopener noreferrer" href="https://www.estrelabeauty.com.br">www.estrelabeauty.com.br</a> e inserir o código recebido no campo cupom de desconto na hora do pagamento, ou ir até uma de nossas lojas e apresentar o código para a vendedora',
          state_return_a: 'Bônus válido de',
          state_return_b: 'Um SMS de confirmação será enviado para <br/> <b>',
          state_return_b2: 'a',
          state_return_c: '</b> (nos próximos minutos).',
          state_return_d: ' Quando for resgatar o bônus mostre esse SMS a um atendente.',
          state_return_obrigado: 'OBRIGADO',
          state2_title: "SEU BÔNUS NÃO FOI GERADO!",
          state2_message: "Tente novamente recomeçando o processo.",
          acesse_store: "Acesse a e-store"
        },
        BonusIndisponivelBlackList: {
          seu_telefone_esta_indisponivel: 'Seu telefone está indisponível <br /> para receber bonificações.',
          voltar: 'VOLTAR'
        },
        BonusIndisponivelLimite: {
          user_limit: 'Você tem bônus a vencer ou já gerou seu limite de bônus da semana.',
          consulta_saldo: 'Consultar saldo',
          voltar: 'VOLTAR'
        },
        Celular: {
          msg_erro_pegar_dados: 'erro ao pegar os dados.',
          inserir_cel_ddd: 'INSIRA SEU CELULAR (COM DDD)',
          rebera_sms_confirmacao: 'Você receberá uma confirmação por SMS',
          avancar: 'AVANÇAR'
        },
        ConfirmaInfo: {
          h1_pergunta_nome: 'SEU NOME É ALEXANDRE?',
          sim: 'SIM',
          nao: 'NÃo'
        },
        Demografico: {
          sua_idade: 'SUA IDADE',
          genero: 'GÊNERO',
          ja_esteve: 'JÁ ESTEVE AQUI ANTES?',
          idade_10_30: '10 - 30 anos',
          idade_31_50: '31 - 50 anos',
          idade_50_ou_mais: '50+',
          masculino: 'Masculino',
          feminino: 'Feminino',
          outro: 'Outro',
          teste: 'Teste',
          sim: 'SIM',
          nao: 'NÃO',
          avancar: 'AVANÇAR'
        },
        LojaErroConexao: {
          erro_internet: 'Sem conexão com a internet.',
          clique_reconectar_internet: 'Clique aqui para reconectar'
        },
        LojaInadimplente: {
          loja_pendencias: 'Sua empresa esta com pendências com a plataforma CRM&BONUS!',
          loja_indisponivel: 'Entre em contato com seu representante para voltar a usar agora mesmo.'
        },
        PesquisaSatisfacao: {
          como_foi_seu_atendimento: 'COMO FOI SEU ATENDIMENTO?',
          medio: 'Médio',
          bom: 'bom',
          otimo: 'Ótimo',
          finalizar: 'FINALIZAR'
        },
        PesquisaSatisfacao2: {
          finalizar: 'PARA FINALIZAR...',
          ruim: 'Ruim',
          medio: 'Médio',
          bom: 'bom',
          otimo: 'Ótimo',
          finalizar2: 'FINALIZAR',
          sua_msg: 'Digite sua mensagem (Opcional)'
        },
        PesquisaSatisfacao3: {
          finalizar: 'PARA FINALIZAR...',
          ruim: 'Ruim',
          medio: 'Médio',
          bom: 'bom',
          otimo: 'Ótimo',
          finalizar2: 'FINALIZAR',
          sua_msg: 'Digite sua mensagem (Opcional)'
        },
      }
    },
  }
};

export { messages };
