import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { OSKLEN_ID } from "../constants/constants";
import {
  faUserCircle,
  faChevronDown,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import imgBonusIndisponivel from "../assets/images/icon_bonus_indisponivel.png";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { i18n } from '../_translate/i18n';

import Header from "../components/Header";
import Footer from "../components/Footer";

library.add(fab, faUserCircle, faChevronDown, faArrowLeft);

class BonusIndisponivelLimite extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  state = {
    id: localStorage.getItem("ID"),
  };

  render() {
    const to = `/?token=${localStorage.getItem("TOKEN")}`;
    const {
      color,
      color_background,
      color_text,
      color_btn,
      color_btn_text_color,
    } = this.props;
    return (
      <div
        style={{ backgroundColor: color_background }}
        className="container_app"
      >
        <Header
          buttonColor={color_btn}
          showGoBack={false}
          goback={() => this.props.history.goBack()}
        />

        <Row style={{ marginBottom: "42px", marginTop: "120px" }}>
          <Col className="text-center">
            <img src={imgBonusIndisponivel} alt="" style={{ width: "96px" }} />
            <p
              style={{ color: color_text }}
              className="cssTextBonusIndisponivel"
            >
              {i18n.t("pages.BonusIndisponivelLimite.user_limit")}
            </p>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              {this.state.id === OSKLEN_ID && (
                <a
                  style={{
                    padding: "16px",
                  }}
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.crmbonus.com/consulta-saldo/NDg0Mg=="
                >
                  {i18n.t("pages.BonusIndisponivelLimite.consulta_saldo")}
                </a>
              )}
              <Link
                style={{
                  backgroundColor: color_btn,
                  borderColor: color_btn,
                  color: color_btn_text_color,
                  width: "232px",
                }}
                className="cssButton btn btn-primary"
                to={to}
              >
                {i18n.t("pages.BonusIndisponivelLimite.voltar")}
              </Link>
            </div>
          </Col>
        </Row>

        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  color: state.customer.cor_layout,
  color_background: state.customer.color_background,
  color_text: state.customer.color_text,
  color_form_text: state.customer.color_form_text,
  color_btn: state.customer.color_btn,
  color_btn_text_color: state.customer.color_btn_text_color,
});

const mapDispatchToProps = (dispatch) => ({});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BonusIndisponivelLimite)
);
