import React, { Component } from "react";
import { Row, Col, Image, Form } from "react-bootstrap";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import {
  faUserCircle,
  faChevronDown,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import HelmetFavicon from "../components/HelmetFavicon";
import Header from "../components/Header";
import Footer from "../components/Footer";

import ruim from "../assets/images/ruim.png";
import medio from "../assets/images/medio.png";
import bom from "../assets/images/bom.png";
import otimo from "../assets/images/otimo.png";
import * as LojaActions from "../store/actions/loja";
import { Link } from "react-router-dom";
import "../assets/styles/app.css";
import { i18n } from '../_translate/i18n';

library.add(fab, faUserCircle, faChevronDown, faArrowLeft);

class PesquisaSatisfacao3 extends Component {
  state = {
    optionSelected: "",
    descricao: "",
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    const { campaign_id } = this.props;
    if (campaign_id === 0) {
      this.props.history.push(`/?token=${localStorage.getItem("TOKEN")}`);
    }
  }

  handlePesquisa = (option) => {
    this.setState({
      optionSelected: option,
    });
  };

  handleSubmit = async () => {
    const option = this.state.optionSelected;
    const descricao = this.state.descricao;
    if (option === "") {
      alert("selecione");
    } else {
      await this.props.preenchePesquisaSatisfacao3(option, descricao);
      this.props.history.push("/bonus-gerado");
    }
  };

  render() {
    const { optionSelected } = this.state;
    const {
      color,
      pergunta_pesquisa_satisfacao3,
      color_form_text,
      color_background,
      color_btn,
      color_btn_text_color,
      color_text,
      favicon,
    } = this.props;
    let show_pergunta = false;
    if (
      optionSelected === "ruim" ||
      optionSelected === "medio" ||
      optionSelected === "bom" ||
      optionSelected === "otimo"
    ) {
      show_pergunta = true;
    }
    return (
      <div
        style={{ backgroundColor: color_background }}
        className="container_app"
      >
        <HelmetFavicon favicon={favicon} />
        <Header showGoBack={true} goback={() => this.props.history.goBack()} />

        <Row style={{ marginBottom: "50px" }}>
          <Col>
            <h1 style={{ color: color_text }} className="text-center">
            {i18n.t("pages.PesquisaSatisfacao3.finalizar")}
            </h1>
          </Col>
        </Row>

        <Row style={{ marginBottom: "50px" }}>
          <Col>
            <h1
              style={{ color: color_text, fontSize: 25 }}
              className="text-center"
            >
              {pergunta_pesquisa_satisfacao3}
            </h1>
          </Col>
        </Row>

        <Row style={{ marginBottom: "30px" }}>
          <Col>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "20px",
              }}
            >
              <div
                className={`boxPesquisaSatisfacao ${
                  optionSelected === "ruim" && "active"
                }`}
                onClick={() => {
                  this.handlePesquisa("ruim");
                }}
              >
                <div style={{ marginBottom: "20px" }}>
                  <Image src={ruim} rounded />
                </div>
                <div>
                  <p style={{ color: color_text }}>{i18n.t("pages.PesquisaSatisfacao3.ruim")}</p>
                </div>
              </div>

              <div
                className={`boxPesquisaSatisfacao ${
                  optionSelected === "medio" && "active"
                }`}
                onClick={() => {
                  this.handlePesquisa("medio");
                }}
              >
                <div style={{ marginBottom: "20px" }}>
                  <Image src={medio} rounded />
                </div>
                <div>
                  <p style={{ color: color_text }}>{i18n.t("pages.PesquisaSatisfacao3.medio")}</p>
                </div>
              </div>

              <div
                className={`boxPesquisaSatisfacao ${
                  optionSelected === "bom" && "active"
                }`}
                onClick={() => {
                  this.handlePesquisa("bom");
                }}
              >
                <div style={{ marginBottom: "20px" }}>
                  <Image src={bom} rounded />
                </div>
                <div>
                  <p style={{ color: color_text }}>{i18n.t("pages.PesquisaSatisfacao3.bom")}</p>
                </div>
              </div>

              <div
                className={`boxPesquisaSatisfacao ${
                  optionSelected === "otimo" && "active"
                }`}
                onClick={() => {
                  this.handlePesquisa("otimo");
                }}
              >
                <div style={{ marginBottom: "20px" }}>
                  <Image src={otimo} rounded />
                </div>
                <div>
                  <p style={{ color: color_text }}>{i18n.t("pages.PesquisaSatisfacao3.otimo")}</p>
                </div>
              </div>
            </div>
          </Col>
        </Row>

        {show_pergunta && (
          <Row style={{ marginBottom: "50px" }}>
            <Col>
              <Form.Group controlId="exampleForm.ControlTextarea1">
                <Form.Control
                  as="textarea"
                  rows="3"
                  placeholder={i18n.t("pages.PesquisaSatisfacao3.sua_msg")}
                  onChange={(event) => {
                    this.setState({
                      ...this.state,
                      descricao: event.target.value,
                    });
                  }}
                  value={this.state.descricao}
                />
              </Form.Group>
            </Col>
          </Row>
        )}

        <Row style={{ marginBottom: "90px" }}>
          <Col>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Link
                style={{
                  backgroundColor: color_btn,
                  borderColor: color_btn,
                  color: color_btn_text_color,
                  width: 232,
                  height: 46,
                  paddingTop: 10,
                  fontSize: 22,
                }}
                className={`cssButton btn btn-primary ${
                  !show_pergunta && "disabled"
                }`}
                onClick={() => this.handleSubmit()}
              >
                {i18n.t("pages.PesquisaSatisfacao3.finalizar2")}
              </Link>
            </div>
          </Col>
        </Row>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  favicon: state.customer.favicon,
  color: state.customer.cor_layout,
  pergunta_pesquisa_satisfacao3: state.customer.pergunta_pesquisa_satisfacao3,
  campaign_id: state.bonus.campaign_id,
  color_background: state.customer.color_background,
  color_text: state.customer.color_text,
  color_form_text: state.customer.color_form_text,
  color_btn: state.customer.color_btn,
  color_btn_text_color: state.customer.color_btn_text_color,
});

const mapDispatchToProps = (dispatch) => ({
  preenchePesquisaSatisfacao3: (pesquisaSatisfacao, descricao) =>
    dispatch(
      LojaActions.preenchePesquisaSatisfacao3(pesquisaSatisfacao, descricao)
    ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PesquisaSatisfacao3)
);
