import React, { Component } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faCheck } from "@fortawesome/free-solid-svg-icons";
import { i18n } from "../_translate/i18n";
import Header from "../components/Header";
import BadgeCrm from "../components/BadgeCrm";
import Footer from "../components/Footer";
library.add(faTimes, faCheck);
class ConfirmaInfo extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div style={{ padding: "50px" }}>
        <Header showGoBack={true} goback={() => this.props.history.goBack()} />

        <Row style={{ marginBottom: "54px" }}>
          <Col>
            <h1 className="text-center">
              {i18n.t("pages.ConfirmaInfo.h1_pergunta_nome")}
            </h1>
          </Col>
        </Row>

        <Row style={{ marginBottom: "100px" }}>
          <Col xs="6" sm="6" md="6" lg="6" xl="6">
            <Button
              style={{ float: "right" }}
              className="cssButtonConfirmacaoInfo btn-block"
              variant="success"
            >
              {i18n.t("pages.ConfirmaInfo.sim")}{" "}
              <FontAwesomeIcon icon="check" size="sm" />
            </Button>
          </Col>

          <Col xs="6" sm="6" md="6" lg="6" xl="6">
            <Button
              style={{ float: "left" }}
              className="cssButtonConfirmacaoInfo btn-block"
              variant="danger"
            >
              {i18n.t("pages.ConfirmaInfo.nao")}{" "}
              <FontAwesomeIcon icon="times" size="sm" />
            </Button>
          </Col>
        </Row>

        <BadgeCrm number="3" />

        <Footer />
      </div>
    );
  }
}

export default ConfirmaInfo;
