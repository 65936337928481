export const findHiperLink = (text) => {
  const regex =
    /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[A-Z0-9+&@#/%=~_|$])/gim;
  const result = regex.exec(text);

  if (result) {
    return result[0];
  } else {
    return undefined;
  }
};

export const transformText = (text) => {
  const link = findHiperLink(text);

  if (link) {
    return text.replace(
      link,
      `<a target="_blank" rel="noopener noreferrer" href="${link}">${link}</a>`
    );
  } else {
    return text;
  }
};
