import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faUserCircle,
  faChevronDown,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import * as LojaActions from "../store/actions/loja";

import HelmetFavicon from "../components/HelmetFavicon";
import ModalCarregando from "../components/ModalCarregando";
import Header from "../components/Header";
import Campanhas from "../components/Campanhas";
import BadgeCrm from "../components/BadgeCrm";
import Garcom from "../components/Garcom";
import Footer from "../components/Footer";
import "../assets/styles/app.css";

library.add(faUserCircle, faChevronDown, faArrowLeft);

class Index extends Component {
  state = {
    showModalCarregando: true,
    infoLoja: null,
  };

  async componentWillMount() {
    const values = queryString.parse(this.props.location.search);
    const token = values.token;
    const infoLoja = await LojaActions.serviceGetInfoLoja(token);

    if (infoLoja != null) {
      await this.props.getInfoLoja(infoLoja);
      
      this.setState({
        infoLoja: infoLoja,
      });

      //manifest dinamico
      document
        .querySelector("#my-manifest")
        .setAttribute("href", "/manifest.json");

      const manifest = {
        short_name: infoLoja.Customer.company,
        name: infoLoja.Customer.company,
        theme_color: "#ffffff",
        background_color: "#ffffff",
        display: "standalone",
        start_url: `https://www.crmbonus.com/?token=${btoa(
          infoLoja.Customer.token
        )}`,
        icons: [
          {
            src: `https://www.crmbonus.com/files/favicon/${localStorage.getItem(
              "FAVICON"
            )}`,
            sizes: "72x72",
            type: "image/png",
          },
        ],
      };
      const stringManifest = JSON.stringify(manifest);
      const blob = new Blob([stringManifest], { type: "application/json" });
      const manifestURL = URL.createObjectURL(blob);
      document.querySelector("#my-manifest").setAttribute("href", manifestURL);
    }
    this.setState({
      showModalCarregando: false,
    });
    //Se deu algum erro na hora de pegar os dados da loja
    if (!infoLoja) {
      this.props.history.push("/erro-conexao");
    }

    if (
      this.props.customer_status_id === 2 ||
      this.props.customer_status_id === 3
    ) {
      //alert(`Token: ${token} / Status: ${this.props.customer_status_id}`);
      this.props.history.push("/inadimplente");
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const { showModalCarregando, infoLoja } = this.state;
    const {
      associar_garcom,
      color_text,
      favicon,
      color_btn,
      text_restaurante,
      color_background,
    } = this.props;
    
    return (
      <div
        style={{ backgroundColor: color_background }}
        className="container_app"
      >
        {/* {i18n.t('buttons.confirmDeleteAccount')} */}
        <Helmet>
          <title>{infoLoja && infoLoja.Customer.company}</title>
          <meta
            name="description"
            content={infoLoja && infoLoja.Customer.company}
          />
        </Helmet>
        <HelmetFavicon
          favicon={`https://www.crmbonus.com/files/favicon/${localStorage.getItem(
            "FAVICON"
          )}`}
        />

        <ModalCarregando show={showModalCarregando} />

        {!showModalCarregando && (
          <div>
            <Header
              showGoBack={false}
              goback={() => this.props.history.goBack()}
            />

            <Row style={{ marginBottom: "50px" }}>
              <Col>
                <h1
                  style={{ color: color_text }}
                  className="text-center"
                  dangerouslySetInnerHTML={{ __html: text_restaurante }}
                />
              </Col>
            </Row>

            <Campanhas />

            <BadgeCrm number="1" />

            {associar_garcom && <Garcom />}

            <Footer />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  id: state.customer.id,
  logo: state.customer.logo,
  favicon: state.customer.favicon,
  text_restaurante: state.customer.text_restaurante,
  associar_garcom: state.customer.associar_garcom,
  customer_status_id: state.customer.customer_status_id,
  color: state.customer.cor_layout,
  color_background: state.customer.color_background,
  color_text: state.customer.color_text,
  color_form_text: state.customer.color_form_text,
  color_btn: state.customer.color_btn,
  color_btn_text_color: state.customer.color_btn_text_color,
});

const mapDispatchToProps = (dispatch) => ({
  getInfoLoja: (infoLoja) => dispatch(LojaActions.getInfoLoja(infoLoja)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Index));
