import React, { Component } from "react";
import { Row, Col, Modal, Button, ListGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as LojaActions from "../store/actions/loja";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { i18n } from '../_translate/i18n';

class Garcom extends Component {
  constructor(props, context) {
    super(props, context);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.state = {
      show: false,
    };
  }

  handleClose() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });
  }

  selectedGarcom(garcom) {
    const lista_garcom = this.props.garcons;

    let garcomSelecionado = "";
    lista_garcom.forEach(function (item, index) {
      lista_garcom[index].active = false;
      if (item.id === garcom.id) {
        garcom.active = true;
        lista_garcom[index] = garcom;
        garcomSelecionado = garcom;
      }
    });

    this.props.selectedGarcom(garcomSelecionado);
  }

  render() {
    const { garcons, garcomSelecionado, color } = this.props;
    return (
      <div>
        <Row style={{ marginBottom: "50px" }}>
          <Col>
            <div className="text-center" onClick={this.handleShow}>
              <FontAwesomeIcon icon="user-circle" size="lg" />{" "}
              <span className="cssNomeGarcom">
                {garcomSelecionado.name || " - "}
              </span>{" "}
              <FontAwesomeIcon icon="chevron-down" />
            </div>
          </Col>
        </Row>

        <Modal
          show={this.state.show}
          onHide={this.handleClose}
          backdrop="static"
        >
          <Modal.Body>
            <h2 className="titulo_modal">
              {i18n.t("components.Garcom.modal_title")}
            </h2>

            <ListGroup style={{ marginBottom: "60px" }}>
              {garcons.map((garcom, idx) => (
                <ListGroup.Item
                  key={garcom.id}
                  action
                  active={garcom.active}
                  className="cssNomeGarcomModal"
                  onClick={(e) => this.selectedGarcom(garcom)}
                >
                  {garcom.name}
                </ListGroup.Item>
              ))}
            </ListGroup>

            <Button
              style={{ backgroundColor: color, borderColor: color }}
              className="cssButton btn-block btn btn-primary"
              variant="primary"
              onClick={this.handleClose}
            >
              {i18n.t("components.Garcom.modal_btn_confirm")}
            </Button>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  garcons: state.customer.garcons,
  garcomSelecionado: state.bonus.garcom,
  color: state.customer.cor_layout,
});

const mapDispatchToProps = (dispatch) => ({
  selectedGarcom: (id) => dispatch(LojaActions.selectedGarcom(id)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Garcom));
