import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class BadgeCrm extends Component {
  render() {
    const { number, color_btn, color_btn_text_color } = this.props;

    return (
      <Row style={{ marginBottom: "50px" }}>
        <Col>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div
              style={{
                color: color_btn_text_color,
                backgroundColor: color_btn,
                borderColor: color_btn,
              }}
              className="circle"
            >
              {number}
            </div>
          </div>
        </Col>
      </Row>
    );
  }
}

export default withRouter(
  connect((state) => ({
    color: state.customer.cor_layout,
    color_background: state.customer.color_background,
    color_text: state.customer.color_text,
    color_form_text: state.customer.color_form_text,
    color_btn: state.customer.color_btn,
    color_btn_text_color: state.customer.color_btn_text_color,
  }))(BadgeCrm)
);
